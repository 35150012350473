import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory, useLocation } from "react-router-dom"
//import logoNissanNow from "../assets/images/logo.png";
import logoNissanClub from "../assets/images/logo-nissan-le-club.png";
import { useTranslation } from 'react-i18next';
import DealerService from "../services/DealerService";


const Layout = ({ children }) => {

  const history = useHistory();

  const [menuDealer, setMenuDealer] = useState();
  const [menuCustomer, setMenuCustomer] = useState();
  //const [menuDirector, setMenuDirector] = useState();

  const location = useLocation();
  //const email = localStorage.getItem('email');
  const role = localStorage.getItem('role');

  let classActive = 0;

  if (location.pathname === '/dealerboard') {
    classActive = 1;
  } else if (location.pathname === '/customerboard') {
    classActive = 2;
  }
  // else if (location.pathname === '/directorboard') {
  //   classActive = 3;
  // }

  useEffect(() => {
    /* Verify roles */
    // TODO : get user name / email
    let role = localStorage.getItem('role');

    const hasAdminRights = role && (role.includes('ROLE_ADMIN') || role.includes('ROLE_DEALER_DIRECTOR'));

    if (hasAdminRights) {
      const adminClass = classActive === 1 ? 'active' : '';
      setMenuDealer(<a href="/dealerboard" title="Gérer les utilisateurs ayant accès à la dealer platform" className={`mx-3 ${adminClass}`}>{t('Administration')}</a>);
    }

    const customerClass = classActive === 2 ? 'active' : '';
    setMenuCustomer(<a href="/customerboard" title="Voir la liste des clients inscrits dans l’espace Nissan le Club e-Carnet" className={`mx-3 ${customerClass}`}>{t('Dashboard Clients')}</a>);

  },[])

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    history.push('/');
  };

  const { t } = useTranslation();

  return (
    <>
    <div className="wrapper">
      <header className="header py-2">
        <div className="container d-flex align-items-center justify-content-between">
          <img src={logoNissanClub} alt="Nissan Le Club" width="160" className="logo-width"/>

          <div className={"menu"}>
            {menuDealer}
            {menuCustomer}
            {/*{menuDirector}*/}
          </div>

          <span className="dark-red cursor-pointer" onClick={()=>logout()}><span className="icon-logout-2 icon align-middle"></span>{t('Logout')}</span>
          <br />
        </div>
      </header>
      <div className="container">
        <main>
          {children}
        </main>
      </div>
    </div>
    <footer className="footer">
      <div className="container">
        <a href={t('url_cookies')} target="_blank" className="ml-3">{t('FAQ / Contact')}</a>
      </div>
    </footer>
    </>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
