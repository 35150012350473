import React, { useState, useEffect, useRef } from 'react';
import UserManage from "../components/Modale/UserManage";
import DealerService from "../services/DealerService";
import Layout from "../components/Layout";
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';

import Select from 'react-select'
//import TimelineService from "../services/TimelineService";

import { Space, Table, Tag, ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import statsService from "../services/StatsService";


const Dealerboard = (props) => {

  const role = localStorage.getItem('role');
  const email = localStorage.getItem('email');

  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(true);

  // Four modal types = "new" or "resend" or "revoke" or "update"
  const [modalType, setModalType] = useState('');
  const [emailModalExist, setEmailModalExist] = useState(false);

  /* Datas from API CAll */
  const [datas, setDatas] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const dealerGroup = localStorage.getItem('dealerGroup');
  const [dealerGroupList, setDealerGroupList] = useState([]);


  /* Local data used for display */
  const [tableDatas, setTableDatas] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});


  /* Filters */
  const [searchFilter, setSearchFilter] = useState('');
  const [dealerFilter, setDealerFilter] = useState('');
  const [activeFilter, setActiveFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [attachStat, setAttachStat] = useState('');
  const [extractStat, setExtractStat] = useState('');
  const [nbLoginsStats, setNbLoginsStat] = useState('');
  const [nbUsersStats, setNbUsersStats] = useState('');

  const history = useHistory();

  const [entity, setEntity] = useState(null);
  const isInitialMount = useRef(true);

  const { t } = useTranslation();

  const columns = [
    {
      title: t('Name'),
      className: 'firstColName',
      dataIndex: 'nom',
      render: (text) => <><span className="font-weight-bold ellipsis">{text[0]}</span><br /><span className="ellipsis" title={text[1]}>{text[1]}</span>{text[2] === '' ? '' : <div><span className="inactive ellipsis">{text[2]}</span></div> }</>,
      sorter: (a, b) => a.nom[0].localeCompare(b.nom[0]),
    },
    {
      title: 'Groupe CRE / CRE',
      dataIndex: 'cre',
      sorter: (a, b) => a.cre[0].localeCompare(b.cre[0]),
    },
    {
      title: t('Last Login'),
      dataIndex: 'lastConnexion',
      render: (text) => <>{text === '-' ? text : moment(text).format('DD/MM/YYYY HH:mm:ss')}</>,
      sorter: (a, b) => a.lastConnexion.localeCompare(b.lastConnexion),
    },
    {
      title: t('Role'),
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: 'Connexions',
      className: 'text-center px-2',
      dataIndex: 'connexions',
      sorter: (a, b) => a.connexions.localeCompare(b.connexions),
      onHeaderCell: () => {
        return {
          'data-after-title': '90 derniers jours',
        };
      },
    },
    {
      title: 'Extractions',
      className: 'text-center px-2',
      dataIndex: 'extractions',
      sorter: (a, b) => a.extractions.localeCompare(b.extractions),
      onHeaderCell: () => {
        return {
          'data-after-title': '90 derniers jours',
        };
      },
    },
    {
      title: 'Rattachements',
      className: 'text-center px-2',
      dataIndex: 'rattachements',
      sorter: (a, b) => a.rattachements.localeCompare(b.rattachements),
      onHeaderCell: () => {
        return {
          'data-after-title': '90 derniers jours',
        };
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'action',
    },
  ];

  useEffect(() => {
    let entity = '';
    if (role.includes('ROLE_ADMIN'))
      entity = "Admin";
    else if (role.includes('ROLE_DEALER_DIRECTOR'))
      entity = 'Groupe ' + localStorage.getItem('dealerGroup');
    else
      entity = dealerGroup + " / " +  localStorage.getItem('dealerName');

    setEntity(entity);
  }, []);

  useEffect(() => {
    /* Verify roles */
    let role = localStorage.getItem('role')
    const hasAdminOrDirectorRights = role && (role.includes('ROLE_ADMIN') || role.includes('ROLE_DEALER_DIRECTOR'));

    if (role === null || !hasAdminOrDirectorRights) {
      localStorage.clear();
      history.push('/login');
    }

    /* Initial API Calls */
    DealerService.getSalesperson({
      token: localStorage.getItem('token'),
      groupName:  role && role.includes('ROLE_ADMIN') ? null : localStorage.getItem('dealerGroup')
    })
    .then((response) => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      }
      setDatas(response.data.salesPersons);
      // setTableDatas(response.data.salesPersons);
      setLoader(false);
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    DealerService.getDealers({
          token: localStorage.getItem('token'),
          groupName: role && role.includes('ROLE_DEALER_DIRECTOR') ? dealerGroup : null
        }

    )
    .then((response) => {
      let activeDealers = response.data['dealers'];
      setDealerList(activeDealers);
    })
    .catch((error) => {
      console.log(error);
      if (error.toString().includes("401")) {
        localStorage.clear();
        history.push('/login');
      }
    });

    DealerService.getDealersGroups({ token: localStorage.getItem('token') })
        .then((response) => {
          let data = response.data['dealers'];
          setDealerGroupList(data);
        })
        .catch((error) => {
          console.log('error with getDealersGroups', error);
        });
    /* /Initial API Calls */
  },[]);

  useEffect(() => {
   statsService.getDealerboardStats({ token: localStorage.getItem('token') })
       .then((response) => {
         const data = response.data['stats'];
         setAttachStat(data['attachStat']);
         setNbLoginsStat(data['nbLoginsStats']);
         setNbUsersStats(data['nbUsersStats']);
         //console.log(data);
       })
       .catch((error) => {
         console.log('error with getDealerboardStats', error);
       });
  }, []);

  useEffect(() => {
    if (!isInitialMount.current && dealerList.length > 0) {
      globalFilter(searchFilter, dealerFilter, activeFilter, roleFilter);
    }
  }, [datas, dealerList]);


  const changeModalType = (newType, email) => {
    //console.log(datas.filter(data => data.email === email)[0]);
    setSelectedUser(datas.filter(data => data.email === email)[0]);
    setEmailModalExist(true);
    setModalType(newType);
  };



  /* Filter methods  */
  const searchFilterChange = (e) => {
    setSearchFilter(e.target.value);
    globalFilter(e.target.value, dealerFilter, activeFilter, roleFilter);
  }

  const dealershipMultiFilterChange = (e) => {

    if(e.length) {
      setDealerFilter(e);
      globalFilter(searchFilter, e, activeFilter, roleFilter);
    }else{
      setDealerFilter(null);
      globalFilter(searchFilter, null, activeFilter, roleFilter);
    }
  }

  const activeFilterChange = (e) => {
    if(e.target.value==="all"){
      globalFilter(searchFilter, dealerFilter, null, roleFilter);
    }else {
      setActiveFilter(e.target.value);
      globalFilter(searchFilter, dealerFilter, e.target.value, roleFilter);
    }
  }

  const roleFilterChange = (e) => {
    if(e.target.value==="all"){
      globalFilter(searchFilter, dealerFilter, activeFilter, null);
    }else {
      setRoleFilter(e.target.value);
      globalFilter(searchFilter, dealerFilter, activeFilter, e.target.value);
    }
  }

  /*
   * Filtering with all the filters
   */
  const globalFilter = (searchFilterParams = "", dealerFilterParams = "", activeFilterParams = "", roleFilterParams = "") => {
    let generalFilter = (data) => {
      if (searchFilterParams
          && (!data.email.toLowerCase().includes(searchFilterParams.toLowerCase())
              && !(data.first_name + ' ' + data.last_name).toLowerCase().includes(searchFilterParams.toLowerCase()))) {
        return false;
      }

      if (dealerFilterParams && !dealerFilterParams.some(obj => obj.value === data.dealer_id)) {
        return false;
      }

      let nicename = data.old_dealer !== 1 ? "Active" : "Inactive";

      if (activeFilterParams && nicename !== activeFilterParams) {
        return false;
      }

      let roles = JSON.parse(data.roles);
      if (roleFilterParams && !roles.some(role => role === roleFilterParams)) {
        return false;
      }

      return true;
    };

    let tempDatas = datas.filter(generalFilter);

    // setTableDatas(tempDatas);

    let dataRender = [];

    tempDatas.map((data, i) => {
      let role = '';
      let dealer = '';
      let entity = '';

      if (data.roles === undefined) data.roles = 'ROLE_DEALER';

      if(data.roles.includes('ROLE_DEALER')) {
        role = 'Vendeur CRE Groupe';
        dealer = (dealerList.length > 0 && data.dealer_id != null
            && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0])
          && dealerList.filter(dealerItem => data.dealer_id === dealerItem.id)[0]

        entity = dealer !== undefined ? ((dealer.dealerGroup !== undefined ? dealer.dealerGroup : 'invalide') + ' / ' + (dealer.name !== undefined ? dealer.name : 'invalide')) : 'invalide';
      }
      if(data.roles.includes('ROLE_DEALER_DIRECTOR')) {
        role = 'Admin Groupe';
        entity = data.dealer_group !== undefined ? data.dealer_group : 'invalide';
      }
      if(data.roles.includes('ROLE_ADMIN')) {
        role = 'Super Admin';
        entity = 'Admin'
      }

      let inactive = data.old_dealer !== 1 && data.validation_date !== null ? '' : t('Inactive');

      dataRender.push({
        key: i,
        nom: [`${data.first_name} ${data.last_name}`, data.email, inactive],
        cre: entity !== null ? entity : 'invalide',
        lastConnexion: data.connection_date ? data.connection_date : '-',
        role: role,
        connexions: `xx`,
        extractions: `xx`,
        rattachements: `xx`,
        action: actions(data.old_dealer, data),
      });
    });

    setTableDatas(dataRender);
  };

  /* /Filter methods */

  /* Display methods
   * status takes old_dealer value from api
   * selectedUser contains all the datas of the current user. It's given to the openModal method when needed
   */

  const actions = (status,selectedUser) => {
      return (<>
        <span className="cursor-pointer" onClick={() => openModal("update", selectedUser) }><span className="icon-pencil fs-13 align-middle"></span> <span className={"text-decoration-underline"}>Modifier</span></span><br />
        {/*<span className="cursor-pointer" onClick={() => false }><span className="icon-2-lock fs-20 align-middle"></span> <span className={"text-decoration-underline"}>Réinitialiser</span></span><br />*/}
        { (status == 1) && <><span onClick={() => openModal("resend", selectedUser)} className="cursor-pointer" title="L’utilisateur va recevoir un e-mail pour activer son compte dans la dealer platform"><span className="icon-2-envelope align-middle"></span> <span className="text-decoration-underline ">{t('Re-send activation link')}</span></span><br /></>  }
        <span className="dark-red cursor-pointer" onClick={() => openModal("revoke", selectedUser)}><span className="icon-2-delete fs-13 align-middle"></span> <span className={"text-decoration-underline"}>Supprimer</span></span>
      </>);
  }

  const dealershipFilterOptionsMulti = () => {

    if(dealerList.length == 0 )
      return

    let dealerListByGroup = [];

    dealerList.forEach((dealer) => {
      if(dealer.dealerGroup !== undefined) {
        (dealerListByGroup[dealer.dealerGroup] = dealerListByGroup[dealer.dealerGroup] || []).push(dealer)
      }
    })

    const groupedOptions = [];

    for (const group in dealerListByGroup) {
      if (dealerListByGroup.hasOwnProperty(group)) {
        const value = dealerListByGroup[group];
        const arrayValue = [];

        value.forEach((dealer) => {
          arrayValue.push({value: dealer.id, label: dealer.name + ' (' + dealer.dealerCode + ')'})
        });

        groupedOptions.push({
          label: group,
          options: arrayValue
        });
      }
    }
    return groupedOptions;

  }


  /* Display methods */

  /* Modal methods */

    /*
     * modalType = "new", 'resend' or "revoke" or "update"
     */
    const openModal = (modalType, user={}) => {
      setShowModal(true);
      setEmailModalExist(false);
      setModalType(modalType);
      setSelectedUser(user);
    }

    const handleClose = () => {
      setShowModal(false);
      setSelectedUser({});
    }

    const showContent = (loader) => {
      if(loader == true)
      {
        return (
          <div style={{ textAlign: "center" }}>
            <Loader
              type="TailSpin"
              color="#C0092D"
              height={100}
              width={100}
            />
          </div>
      )
      } else {
        return (
          <div className="table-responsive">
            <ConfigProvider locale={frFR} theme={{
              components: {
                Table: {
                  cellPaddingBlock: '10px',
                },
              }, }}>
              <Table columns={columns} dataSource={tableDatas} className={"table table-striped table-align-middle fs-13 table-custom"} />
            </ConfigProvider>
          </div>
        )

      }
    }
  /* /Modal methods */


  return (
    <Layout>
      <div className="white-container">
        <h2 className="with-border">{t('Menu.item1')}</h2>
        <p className="with-border">
          {email}
          {entity != '' &&
              <span><br/> ({entity})</span>
          }
        </p>
        <div className="row mb-4">
          <div className="col-md-6 col-xl-3 mb-3 mb-xl-0 d-flex">
            <div className="card card-stats flex-grow-1">
              <div className="card-body d-flex align-items-center">
                <span className="icon-2-user-bold fs-50"></span>
                <h5 className="card-title"><strong>{ attachStat || 'xx' }</strong><br />Rattachements<br /><span className="last-mentions">90 derniers jours</span></h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-3 mb-xl-0 d-flex">
            <div className="card card-stats flex-grow-1">
              <div className="card-body d-flex align-items-center">
                <span className="icon-2-download fs-40"></span>
                <h5 className="card-title"><strong>{ extractStat || 'xx' }</strong><br />Extractions<br /><span className="last-mentions">90 derniers jours</span></h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-3 mb-xl-0 d-flex">
            <div className="card card-stats flex-grow-1">
              <div className="card-body d-flex align-items-center">
                <span className="icon-2-sign-in fs-40"></span>
                <h5 className="card-title"><strong>{ nbLoginsStats || 'xx' }</strong><br />Connexions totales<br /><span className="last-mentions">90 derniers jours</span></h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 mb-3 mb-xl-0 d-flex">
            <div className="card card-stats flex-grow-1">
              <div className="card-body d-flex align-items-center">
                <span className="icon-2-sign-in fs-40"></span>
                <h5 className="card-title"><strong>{ nbUsersStats || 'xx/yy' }</strong><br />Utilisateurs connectés<br /><span className="last-mentions"></span></h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 mx-xl-n1">
          <div className="col-md-6 col-xl-2 px-xl-1 mb-3 mb-xl-0">
            <div className="input-with-icon">
              <input type="text" value={searchFilter} onChange={(e) => searchFilterChange(e)} className="form-control mb-0 fs-13" placeholder={t('Search')} />
              <span className="icon-search icon"></span>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 px-xl-1 mb-3 mb-xl-0">
            <div className="">
              <Select
                options={ dealershipFilterOptionsMulti() }
                className="react-select-container mb-0 fs-13"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                isMulti={true}
                placeholder={t('All concessions')}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                formatGroupLabel={({ label }) => (
                    <div>
                        <strong>{label}</strong>
                    </div>
                )}
                formatOptionLabel={({ label }) => (
                    <div title={label}>
                        {label}
                    </div>
                )}
                onChange={ (event) => { dealershipMultiFilterChange(event); }   } />
            </div>
          </div>
          <div className="col-md-6 col-xl-2 px-xl-1 mb-3 mb-xl-0">
            <div className="select">
              <select defaultValue="" onChange={(event) => activeFilterChange(event)}  className="form-control mb-0 fs-13">
                <option disabled value="" >{t('Activation status')}</option>
                <option value="all">{t('All')}</option>
                <option value="Active">{t('Active')}</option>
                <option value="Inactive">{t('Inactive')}</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-xl-2 px-xl-1 mb-3 mb-xl-0">
            <div className="select">
              <select defaultValue="" onChange={(event) => roleFilterChange(event)} className="form-control mb-0 fs-13">
                <option disabled value="" >{t('Role')}</option>
                <option value="all">Tous</option>
                <option value="ROLE_ADMIN">Super Admin</option>
                <option value="ROLE_DEALER_DIRECTOR">Admin Groupe</option>
                <option value="ROLE_DEALER">Vendeur CRE Groupe</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 col-xl-2 px-xl-1 mb-3 mb-xl-0 ml-auto">
            <button className="button red with-icon fs-13 ml-auto" title="Créer un compte utilisateur à la Dealer Platform" onClick={() => openModal("new")}><span className="icon-profile icon-profile-new icon"></span><span className="py-1 pl-4">{t('Add a new salerperson')}</span></button>
          </div>

        </div>
          {
            showContent(loader)
          }
      </div>

      { showModal &&
      <UserManage
        dealerGroupList={dealerGroupList}
        dealerList={dealerList}
        showModal={showModal}
        handleClose={() => handleClose()}
        modalType={modalType}
        emailModalExist={emailModalExist}
        onChangeModalType={changeModalType}
        selectedUser={selectedUser} />
      }

    </Layout>
  );
}


export default Dealerboard
